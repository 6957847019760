import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import QuestionHelper from 'components/QuestionHelper'
import Row from 'components/Row'
import { AlertTriangle } from 'react-feather'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'

import { PendingModalContainer } from '.'

export enum PendingModalError {
  TOKEN_APPROVAL_ERROR,
  PERMIT_ERROR,
  CONFIRMATION_ERROR,
  WRAP_ERROR,
}

interface ErrorModalContentProps {
  errorType: PendingModalError
  onRetry: () => void
  quoteId?: string
}

function getErrorContent(errorType: PendingModalError, quoteId?: string) {
  switch (errorType) {
    case PendingModalError.TOKEN_APPROVAL_ERROR:
      return {
        title: <Trans>Token approval failed</Trans>,
        label: <Trans>Why are approvals required?</Trans>,
        tooltipText: (
          <Trans>
            This provides the ElectroSwap protocol access to your token for trading. For security, this will expire
            after 30 days.
          </Trans>
        ),
      }
    case PendingModalError.PERMIT_ERROR:
      return {
        title: <Trans>Permit approval failed</Trans>,
        label: <Trans>Why are permits required?</Trans>,
        tooltipText: (
          <Trans>Permit2 allows token approvals to be shared and managed across different applications.</Trans>
        ),
      }
    case PendingModalError.CONFIRMATION_ERROR:
      return {
        title: <Trans>Swap failed</Trans>,
        label: (
          <>
            <Trans>Trying adjusting your slippage.</Trans>
            {quoteId !== undefined ? (
              <>
                <br /> Quote ID: {quoteId}
              </>
            ) : undefined}
          </>
        ),
        tooltipText: (
          <Trans>
            Slippage refers to the difference between the expected price of a trade and the actual price at which it is
            executed
          </Trans>
        ),
      }
    case PendingModalError.WRAP_ERROR:
      return {
        title: <Trans>Wrap failed</Trans>,
      }
  }
}

export function ErrorModalContent({ errorType, onRetry, quoteId }: ErrorModalContentProps) {
  const theme = useTheme()

  const { title, label, tooltipText } = getErrorContent(errorType, quoteId)

  return (
    <PendingModalContainer gap="lg">
      <AlertTriangle data-testid="pending-modal-failure-icon" strokeWidth={1} color={theme.critical} size="48px" />
      <ColumnCenter gap="md">
        <ThemedText.HeadlineSmall>{title}</ThemedText.HeadlineSmall>
        <Row justify="center">
          {label && <ThemedText.BodySmall color="neutral2">{label}</ThemedText.BodySmall>}
          {tooltipText && <QuestionHelper text={tooltipText} />}
        </Row>
      </ColumnCenter>
      <Row justify="center">
        <ButtonPrimary marginX="24px" marginBottom="16px" onClick={onRetry}>
          <Trans>Retry</Trans>
        </ButtonPrimary>
      </Row>
    </PendingModalContainer>
  )
}
