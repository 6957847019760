import { ChainId } from '@electroswap/sdk-core'
import AppRpcProvider from 'rpc/AppRpcProvider'
import AppStaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider'
import StaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider'

import { SupportedInterfaceChain } from './chains'
import { RPC_URLS } from './networks'

const providerFactory = (chainId: SupportedInterfaceChain, i = 0) => {
  const providers = []
  for (let i = 0; i < RPC_URLS[chainId].length; i++) {
    providers.push(new AppStaticJsonRpcProvider(chainId, RPC_URLS[chainId][i]))
  }
  providers.reverse()
  return providers
}

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: { [key in SupportedInterfaceChain]: StaticJsonRpcProvider } = {
  [ChainId.ELECTRONEUM]: new AppRpcProvider(ChainId.ELECTRONEUM, providerFactory(ChainId.ELECTRONEUM)),
  [ChainId.ELECTRONEUM_TEST]: new AppRpcProvider(ChainId.ELECTRONEUM_TEST, providerFactory(ChainId.ELECTRONEUM_TEST)),
}

export const DEPRECATED_RPC_PROVIDERS: { [key in SupportedInterfaceChain]: AppStaticJsonRpcProvider } = {
  [ChainId.ELECTRONEUM]: providerFactory(ChainId.ELECTRONEUM)[1],
  [ChainId.ELECTRONEUM_TEST]: providerFactory(ChainId.ELECTRONEUM_TEST)[0],
}
